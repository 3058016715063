<template>
  <v-card class="mt-10 mb-10">
    <v-card-title> הוספת מותג חדש</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form">
        <v-row class="basic mt-2">
          <v-col cols="6" md="3" class="py-0">
            <v-text-field
              background-color="white"        
              outlined
              dense
              v-model="item.name"
              @keydown="name_exist = false"
              :rules="[(v) => !!v || 'שדה חובה', !name_exist || '']"
            > 
              <template slot="label">
                <strong class="red--text">*</strong>
                שם מותג       
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="6" md="3" class="py-0">
            <v-file-input
              prepend-icon=""
              prepend-inner-icon="image"
              background-color="white"
              outlined
              dense
              label="תמונה"
              v-model="item.image"
            ></v-file-input>
          </v-col>

          <v-col cols="6" md="3" class="py-0">
            <v-text-field
              background-color="white"
              readonly
              outlined
              dense
              label="סטטוס"
              :value="item.status ? 'פעיל' : 'לא פעיל'"
            >
              <template slot="append">
                <v-switch
                  v-model="item.status"
                  class="mt-0"
                  hide-details
                ></v-switch>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-end px-4">
      <div class="d-flex">
        <v-btn
          @click="close_form()"
          x-large
          depressed
          height="40"
          color="black--text"
        >
          <v-icon>arrow_forward</v-icon>
        </v-btn>
        <v-btn
          @click="save()"
          :loading="save_loader"
          :disabled="save_loader"
          x-large
          depressed
          height="40"
          color="black white--text ms-3"
          >שמירה</v-btn
        >
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Form",
  data() {
    return {
      save_loader: false,
      name_exist: false,
      item: {
        name: "",
        status: true,
        image: null,
      },
    };
  },
  methods: {
    close_form() {
      if (this.save_loader) {
        this.$emit("update_table");
        this.save_loader = false;
        console.log("yes");
      }
      this.$emit("close_form");
    },
    save() {
      if (this.$refs.form.validate() && !this.save_loader) {
        this.save_loader = true;
        const formData = new FormData();

        Object.entries(this.item).forEach((e) => {
          formData.append(e[0], e[1]);
        });

        this.$store
          .dispatch("public__request", {
            config: {
              url: "brands",
              method: "POST",
            },
            data: formData,
          })
          .then((res) => {
            console.log(res);
            this.close_form();
          })
          .catch((err) => {
            this.save_loader = false;
            let errors = err.response.data.data;
            Object.keys(errors).forEach((e) => {
              console.log(e);
              errors[e].forEach((s) => {
                if (s.includes("already been taken")) {
                  this[`${e}_exist`] = true;
                }
              });
            });
            console.log(err.response);
          });
      }
    },
  },
};
</script>
