<template>
  <div>
    <v-skeleton-loader v-if="!data_loaded" type="table"></v-skeleton-loader>
    <v-card v-if="data_loaded">
      <v-card-title>
        <v-icon x-large>approval</v-icon>
        <h1 class="ms-3">פרסומות</h1>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          class="text-center"
          :headers="headers"
          :items="items"
          mobile-breakpoint="0"
        >
          <template v-slot:item="{ item }">
            <tr @click="edit_row(item)">
              <td class="d-flex align-center">
                <img
                  width="35"
                  class="ml-3"
                  v-if="item.uploaded_img"
                  :src="`${$store.state.def_path}/${item.uploaded_img}`"
                  alt=""
                />
                <img
                  width="35"
                  class="ml-3"
                  v-if="!item.uploaded_img"
                  :src="`${$store.state.def_path}/uploads/none_img.png`"
                  alt=""
                />
                {{ item.name }}
              </td>
              <td>
                <v-chip
                  x-small
                  class="px-2"
                  :color="item.status ? 'success black--text' : 'error'"
                >
                  {{ item.status ? "פעיל" : "לא פעיל" }}
                </v-chip>
              </td>
              <td>{{ item.created_at }}</td>
            </tr>

            <tr v-if="item.form" class="grey lighten-3">
              <td colspan="10">
                <v-form :ref="`form_${item.id}`">
                  <v-row class="basic mt-2 mb-4 pt-3">
                    <v-col class="py-0">
                      <v-text-field
                        @keydown="input_change(item, 'name_exist')"
                        v-model="item.name"
                        background-color="white"
                        outlined
                        dense
                        :rules="[
                          (v) => !!v || 'שדה חובה',
                          !item.name_exist || '',
                        ]"
                      >
                        <template slot="label">
                          <strong class="red--text">*</strong>
                          שם מותג
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col class="py-0">
                      <v-file-input
                        v-model="item.image"
                        prepend-icon=""
                        prepend-inner-icon="image"
                        background-color="white"
                        outlined
                        dense
                        label="תמונה"
                      ></v-file-input>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        background-color="white"
                        readonly
                        outlined
                        dense
                        label="סטטוס"
                        :value="item.status ? 'פעיל' : 'לא פעיל'"
                      >
                        <template slot="append">
                          <v-switch
                            v-model="item.status"
                            class="mt-0"
                            hide-details
                          ></v-switch>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col class="py-0 d-flex justify-space-between">
                      <v-btn
                        depressed
                        height="40"
                        width="59%"
                        color="black white--text"
                        class="me-2"
                        :loading="item.save_loader"
                        :disabled="item.save_loader"
                        @click="save_update(item)"
                        v-if="$store.getters.permission('brands edit')"
                        >שמירה</v-btn
                      >
                      <v-btn
                        v-if="$store.getters.permission('brands delete')"
                        @click="delete_row(item.id)"
                        depressed
                        height="40"
                        width="35%"
                        color="white "
                      >
                        <v-icon color="black">delete_sweep </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <DeleteDialog
      @delete_done="$emit('update_data')"
      @close_delete="delete_obj.delete_prop = false"
      :delete_obj="delete_obj"
    ></DeleteDialog>
  </div>
</template>

<script>
import DeleteDialog from "@/components/public/delete";
export default {
  name: "Table",
  props: ["data_loaded", "items"],
  data() {
    return {
      delete_obj: {
        delete_prop: false,
        delete_url: "brands",
        title: "מחיקת קטגוריה",
        id: null,
      },
      headers: [
        {
          value: "name",
          text: "שם מותג",
          align: "center",
        },
        {
          value: "status",
          text: "סטטוס",
          align: "center",
        },
        {
          value: "created_at",
          text: "תאריך הוספה",
          align: "center",
        },
      ],
    };
  },
  components: {
    DeleteDialog,
  },
  methods: {
    input_change(item, type) {
      this.$set(item, `${type}`, false);
    },
    edit_row(item) {
      this.$emit("close_form");
      this.items.forEach((e) => {
        e.form = false;
      });
      item.form = true;
    },
    save_update(item) {
      console.log(!item.save_loader);
      if (this.$refs[`form_${item.id}`].validate() && !item.save_loader && this.$store.getters.permission('brands edit')) {
        item.save_loader = true;
        const formData = new FormData();
        Object.entries(item).forEach((e) => {
          formData.append(e[0], e[1]);
        });
        this.$store
          .dispatch("public__request", {
            config: {
              url: `brands/${item.id}`,
              method: "post",
            },
            data: formData,
          })
          .then((res) => {
            item.save_loader = false;
            this.$emit("update_data");
          })
          .catch((err) => {
            item.save_loader = false;
            let errors = err.response.data.data;
            Object.keys(errors).forEach((e) => {
              errors[e].forEach((s) => {
                if (s.includes("already been taken")) {
                  this.$set(item, `${e}_exist`, true);
                }
              });
            });
          });
      }
    },
    delete_row(id) {
      this.delete_obj.id = id;
      this.delete_obj.delete_prop = true;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.basic {
  .col {
    flex: 0 0 20%;
    max-width: 20%;
    @media (max-width: 991px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
</style>
